import React, { ReactElement, useEffect, useState } from 'react';
import { AiOutlineHeart } from 'react-icons/ai';
import { BsChatHeart } from 'react-icons/bs';
import { FaRegAddressCard } from 'react-icons/fa';
import { GiExitDoor } from 'react-icons/gi';
import { GrDocumentTime } from 'react-icons/gr';
import { RxHome } from 'react-icons/rx';
import { SlSettings } from 'react-icons/sl';

import { LogoutButton } from '@components/Button';
import { Button } from '@components/Button';
import { ButtonStyle } from '@components/Button/Button';
import { TooltipWrapper } from '@components/Tooltip/Tooltip';
import { HighlightTag } from '@components/Typography/Tag/Tag';
import { useCustomer } from '@hooks/customer/useCustomer';
import { isErrorResponse } from '@interfaces/BulkAPI';
import { PT_REFERRAL_GROUPS } from '@lib/constants';

import { Breadcrumbs } from '../Breadcrumbs';
import { Container } from '../Container';

const ICON_SIZE = '1.5rem';

export function AccountHeader(): ReactElement {
  const { customer } = useCustomer();
  const [storeCredit, setStoreCredit] = useState<number>();

  useEffect(() => {
    let mounted = true;
    fetch('/api/customer/storeCredit')
      .then((res) =>
        res.json().then((credit) => {
          if (!isErrorResponse(credit)) {
            if (mounted) setStoreCredit(credit.data);
          }
        })
      )
      .catch((err) => {
        // no store credit
        if (mounted) setStoreCredit(undefined);
      });

    return function cleanup() {
      mounted = false;
    };
  });

  return (
    <Container>
      <Breadcrumbs
        crumbs={[
          { title: 'home', path: '/' },
          { title: 'My Account', path: '/account' },
        ]}
      />
      <nav className="mx-auto my-5 sm:mt-2.5">
        <ul className="mx-auto my-0 flex list-none flex-wrap justify-center gap-4 text-grey-dark tiny:mx-0">
          <li className="w-fit min-w-12.5">
            <TooltipWrapper text="Account Home">
              <Button
                href="/account/home"
                buttonStyle={ButtonStyle.tertiary}
                icon={<RxHome size={ICON_SIZE} />}
              />
            </TooltipWrapper>
          </li>
          <li className="w-fit min-w-12.5">
            <TooltipWrapper text="Your Favourites">
              <Button
                href="/account/favourites"
                buttonStyle={ButtonStyle.tertiary}
                icon={<AiOutlineHeart size={ICON_SIZE} />}
              />
            </TooltipWrapper>
          </li>
          <li className="w-fit min-w-12.5">
            <TooltipWrapper text="Order History">
              <Button
                href="/account/orders"
                buttonStyle={ButtonStyle.tertiary}
                icon={<GrDocumentTime size={ICON_SIZE} />}
              />
            </TooltipWrapper>
          </li>
          <li className="w-fit min-w-12.5">
            <TooltipWrapper text="Saved Addresses">
              <Button
                href="/account/addresses"
                buttonStyle={ButtonStyle.tertiary}
                icon={<FaRegAddressCard size={ICON_SIZE} />}
              />
            </TooltipWrapper>
          </li>
          <li className="w-fit min-w-12.5">
            <TooltipWrapper text="Account Settings">
              <Button
                href="/account/settings"
                buttonStyle={ButtonStyle.tertiary}
                icon={<SlSettings size={ICON_SIZE} />}
              />
            </TooltipWrapper>
          </li>
          {customer && PT_REFERRAL_GROUPS.includes(customer.customerGroupId) ? (
            <li style={{ position: 'relative' }} className="w-fit min-w-12.5">
              <TooltipWrapper text="Referral Program">
                <Button
                  href="/account/referral-program"
                  buttonStyle={ButtonStyle.tertiary}
                  icon={<BsChatHeart size={ICON_SIZE} />}
                />
              </TooltipWrapper>

              <HighlightTag bgColour="var(--new)">New</HighlightTag>
            </li>
          ) : null}
          <li className="w-fit min-w-12.5">
            <TooltipWrapper text="Log Out">
              <LogoutButton
                buttonStyle={ButtonStyle.tertiary}
                icon={<GiExitDoor size={ICON_SIZE} />}
              />
            </TooltipWrapper>
          </li>
        </ul>
      </nav>
      {storeCredit && storeCredit > 0 ? (
        <div className="mx-auto my-4 w-full max-w-[--narrow-page] text-center">
          Your account currently has{' '}
          <strong>${storeCredit?.toFixed(2)} store credit</strong> applied to
          it.
        </div>
      ) : (
        <></>
      )}
    </Container>
  );
}
